/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyDnGkxPLfV78VAVZaLnSTsUy8Zj3buUVKg",
  "appId": "1:435434324091:web:33eba092c607c75fad22de",
  "authDomain": "schooldog-i-screven-ga.firebaseapp.com",
  "measurementId": "G-6H39M823GB",
  "messagingSenderId": "435434324091",
  "project": "schooldog-i-screven-ga",
  "projectId": "schooldog-i-screven-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-screven-ga.appspot.com"
}
